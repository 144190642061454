// Override default variables before the import
$body-bg: #fff;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css');

.cursor-pointer {
    cursor: pointer;
}

.no-records-message {
    display: inline-block;
    padding: 1rem 3rem;
    margin: 1rem;
    border-radius: .25rem;
    border: solid 1px #e8e8e8;
    background-color: #f8f8f8;
    color: #A66;
}
