#app-container {
    padding: 2rem 0;
}

#login-box {
    max-width: 20rem;
    margin: 2rem auto;
    text-align: center;
    background-color: #fafafa;
    border: solid 1px #999;
    border-radius: 0.25rem;
    padding-top: 1rem;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
}

.bi-icon {
    cursor: pointer;
}

.copy-to-clipboard {
    .bi-icon {
        margin-left: 1rem;
    }
}

.app-title {
    background: #000074;
    color: white;
    padding: 1rem;
}

.vehicle-details,
.customer-details {
    display: flex;
    flex-direction: column;
}

#main-nav {
    li.nav-item a,
    .navbar-brand {
        color: white;
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    .bi-icon {
        margin-right: 0.5rem;
    }
}

.work-order-vehicle-attributes {
    font-size: .875rem;
    color: #666;
}

.ordered-part-row td {
    font-style: italic;
}
